import {
  CatalogProduct,
  CatalogProductBrand,
  CatalogProductCategoryPath,
  CatalogProductDocument,
  CatalogProductExtraData,
  CatalogProductImage,
  CatalogProductTag,
  CatalogProductVariant,
} from "../api/backend/backend-api"
import { productPagePath } from "../root/routing/urls"
import { Optional } from "../types/common"
import {
  Product,
  ProductBrand,
  ProductCategory,
  ProductCharacteristics,
  ProductExtraData,
  ProductImage,
  ProductInfo,
  ProductMedia,
  ProductMetadata,
  // ProductShopifyData,
  ProductTag,
  ProductVariant,
  ProductVariantCharacteristics,
  ProductVariantContents,
  ProductVariantInfo,
  ProductVariantInventory,
  ProductVariantMedia,
  ProductVariantMetadata,
} from "../types/product"

export const hasTag = (product: CatalogProductDocument, tag: string) =>
  !!product.product.metadata.tags?.find((x) => x.value === tag)

export const isNew = (product: CatalogProductDocument) =>
  hasTag(product, "new:true")

const toImage = (input: CatalogProductImage): ProductImage => ({
  asset: input.asset,
  title: input.title ?? undefined,
  size: input.size ?? undefined,
})

const toCategoryPath = (input: CatalogProductCategoryPath): ProductCategory[] =>
  input.path.map((x) => ({
    code: x.code,
    level: x.level,
    name: x.name,
    slug: "", // todo
  }))

const toBrand = (input: CatalogProductBrand): Optional<ProductBrand> =>
  input.code && input.name
    ? {
        code: input.code,
        name: input.name,
      }
    : undefined

const toTag = (input: CatalogProductTag): ProductTag => ({
  code: input.code,
  key: input.key,
  name: input.name ?? "",
  value: input.value,
  categoryName: input.categoryName ?? "",
})

const getMetaTag = (tags: CatalogProductTag[], tag: string) =>
  tags.find((x) => x.value === tag)

const hasMetaTag = (tags: CatalogProductTag[], tag: string) =>
  getMetaTag(tags, tag) !== undefined

const toInventoryInfo = (
  input: CatalogProductVariant
): ProductVariantInventory => ({
  available: input.inventory.maxSellQuantity > 0,
  currency: "eur",
  maxSellQuantity: input.inventory.maxSellQuantity,
  price: input.inventory.price,
  compareAtPrice: input.inventory.compareAtPrice ?? undefined,
  discountPercent: input.inventory.discountPercent ?? 0,
  bestSeller: input.inventory.bestSeller ?? false,
})

const toVariantInfo = (input: CatalogProductVariant): ProductVariantInfo => ({
  variantId: input.info.variantId,
  default: input.info.default,
  slug: input.info.slug ?? undefined,
  color: input.details.color
    ? {
        name: input.details.color.name,
        asset: input.details.color.texture?.asset ?? undefined,
        hex: input.details.color.value?.hex ?? undefined,
      }
    : undefined,
  quantity: input.details.quantity ? input.details.quantity.value : undefined,
  validity: {
    validFrom: input.info.validFrom ?? undefined,
    validTo: input.info.validTo ?? undefined,
  },
})

const toVariantMedia = (input: CatalogProductVariant): ProductVariantMedia => ({
  productImages: input.contents.productImages?.map(toImage) ?? [],
  campaignImages: input.contents.campaignImages?.map(toImage) ?? [],
})

const toVariantContents = (
  input: CatalogProductVariant
): ProductVariantContents => ({
  name: input.contents.name,
  shortDescription: input.contents.shortDescription?.html ?? undefined,
  longDescription: input.contents.longDescription?.html ?? undefined,
  highlights: input.contents.highlights?.html ?? undefined,
  instructions: input.contents.instructions?.html ?? undefined,
  ingredients: input.contents.ingredients?.html ?? undefined,
  composition: input.contents.composition?.html ?? undefined,
  testResults: input.contents.testResults?.html ?? undefined,
  fragrance: input.contents.fragrance?.html ?? undefined,
  fragranceFamily: input.contents.fragranceFamily?.html ?? undefined,
  technology: input.contents.technology?.html ?? undefined,
  plus: input.contents.plus?.html ?? undefined,
  safetyWarnings: input.contents.safetyWarnings?.html ?? undefined,
  contacts: input.contents.contacts?.html ?? undefined,
})

const toVariantMetadata = (
  input: CatalogProductVariant
): ProductVariantMetadata => ({
  brand: input.metadata.brand ? toBrand(input.metadata.brand) : undefined,
  categoryPath: input.metadata.categoryPaths?.[0]
    ? toCategoryPath(input.metadata.categoryPaths?.[0])
    : undefined,
  tags: input.metadata.tags?.map(toTag),
})

const toVariantCharacteristics = (
  input: CatalogProductVariant
): ProductVariantCharacteristics => ({
  new: input.metadata.tags
    ? hasMetaTag(input.metadata.tags, "new:true")
    : undefined,
  bestSeller: input.metadata.tags
    ? hasMetaTag(input.metadata.tags, "bestseller:true")
    : undefined,
  exclusive: input.metadata.tags
    ? hasMetaTag(input.metadata.tags, "exclusive:true")
    : undefined,
})

const toProductCharacteristics = (
  input: CatalogProduct,
  defaultVariant: ProductVariant
): ProductCharacteristics => ({
  new:
    hasMetaTag(input.metadata.tags ?? [], "new:true") ||
    defaultVariant.characteristics?.new,
  bestSeller:
    hasMetaTag(input.metadata.tags ?? [], "bestseller:true") ||
    defaultVariant.characteristics?.bestSeller,
  exclusive:
    hasMetaTag(input.metadata.tags ?? [], "exclusive:true") ||
    defaultVariant.characteristics?.exclusive,
})

// export const toShopifyData = (
//   input: CatalogProductVariant
// ): ProductShopifyData => ({
//   shopifyId: input.shopify?.shopifyId ?? undefined,
//   storefrontId: input.shopify?.shopifyStorefrontId ?? undefined,
// })

export const toVariant = (input: CatalogProductVariant): ProductVariant => ({
  info: toVariantInfo(input),
  // shopify: toShopifyData(input),
  media: toVariantMedia(input),
  metadata: toVariantMetadata(input),
  characteristics: toVariantCharacteristics(input),
  inventory: toInventoryInfo(input),
  contents: toVariantContents(input),
})

const toProductInfo = (input: CatalogProductDocument): ProductInfo => ({
  productId: input.product.info.productId,
  slug: input.product.info.productSlug,
  url: productPagePath(input.product.info.productSlug),
  name: input.product.contents.name,
})

const toProductMetadata = (
  input: CatalogProduct,
  defaultVariant: ProductVariant
): ProductMetadata => ({
  brand: defaultVariant.metadata?.brand,
  categoryPath: input.metadata?.categoryPaths?.[0]?.path?.[0]
    ? toCategoryPath(input.metadata.categoryPaths[0])
    : defaultVariant.metadata.categoryPath,
  tags: input.metadata?.tags?.[0]
    ? input.metadata.tags?.map(toTag)
    : defaultVariant.metadata.tags,
})

const toProductMedia = (
  input: CatalogProduct,
  defaultVariant: ProductVariant
): ProductMedia => ({
  productImages: input.contents.productImages?.[0]
    ? input.contents.productImages.map(toImage)
    : defaultVariant?.media.productImages,
  campaignImages: input.contents.campaignImages?.[0]
    ? input.contents.campaignImages.map(toImage)
    : defaultVariant?.media.campaignImages,
})

export const toProductExtraData = (
  input?: CatalogProductExtraData
): ProductExtraData => ({
  promotions:
    input?.promotions.map((x) => ({
      code: x.code,
      logoUrl: x.logoUrl ?? undefined,
    })) ?? [],
  noPromo: input?.noPromo ?? false,
})

export const toProduct = (
  input: CatalogProductDocument,
  defaultVariantId?: string
): Product => {
  const variants = input?.variants?.map(toVariant)
  const defaultVariantIdValue =
    defaultVariantId ?? input.defaultVariant.info.variantId
  const defaultVariant = variants?.find(
    (x) => x.info.variantId === defaultVariantIdValue
  ) as ProductVariant

  return {
    info: toProductInfo(input),
    media: toProductMedia(input.product, defaultVariant),
    metadata: toProductMetadata(input.product, defaultVariant),
    characteristics: toProductCharacteristics(input.product, defaultVariant),
    variants,
    defaultVariant,
    extraData: toProductExtraData(input.extraData ?? undefined),
  }
}
